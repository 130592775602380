








































import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "@/components/Notification";
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "remove-appointment",
  props: {
    value: Boolean,
    event: Object,
  },
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    remove() {
      this.loading = true;
      getAPI
        .delete("/patient/deleteDate/" + this.event.data.uuid)
        .then(() => {
          this.loading = false;
          this.close();
          this.$emit("removed");
          notifyInfo("Appointment removed successfully");
        })
        .catch((error: any) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.$emit("error-removed");
        });
    },
    close() {
      this.$emit("input", false);
    },
  },
});
